.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
p {
  margin-bottom: 8px;
  font-size: 16px;
}